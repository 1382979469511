import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import translations from "app/assets/files/translations/navbar.json";
import BasicLink from "app/components/basic/Link";
import SearchbarLabel from "app/components/basic/SearchbarLabel/SearchbarLabel";
import determineSessionStatus, {
	SessionStatusType,
} from "app/components/composites/SessionModal/determineSessionStatus";
import { useEwillsForm } from "app/modules/eWills/hooks/useEWillsForm";
import { useHdbParkingForm } from "app/modules/hdb-car-parking/hooks/useHdbParkingForm";
import { getCookie } from "app/utils/cookies";
import { useGlobalLanguageState } from "app/utils/internationalization/GlobalLanguageProvider";
import { ITranslatedObject } from "app/utils/internationalization/TranslationPageRenderer";
import { deleteSid } from "app/utils/redis";
import { setLogout, withAuthRedirect } from "app/utils/url";
import Router from "next/router";
import { useCallback, useEffect, useState } from "react";
import { showSimplifiedHeaderAndFooter } from "../util";
import NavLink from "./NavLink";
import NavbarDrawer from "./Navbar.Drawer";
import "./Navbar.scss";
import {
	MyLegacyNavbar,
	NavbarAvatarButton,
	NavbarDrawerButton,
	NavbarDrawerButtonWrapper,
	NavbarText,
	NotificationAlert,
	NotificationCountLabel,
	ProfileDropdownWrapper,
	ProfileNameWrapper,
} from "./styles";
import { UserAllocatedDistribution } from "app/modules/eWills/pages/Steps/Review/components/ResiduaryEstateCard/styles";
import { NavItemProps } from "@lifesg/react-design-system/navbar";
import { useRouter } from "next/router";

interface IProps {
	notifications?: number;
}
enum NavbarLinkList {
	HOME = "/",
	END_OF_LIFE_PLANNING = "/end-of-life-planning/",
	WHEN_DEATH_HAPPENS = "/when-death-happens/",
	VAULT = "/vault/",
	SEARCH = "/results/",
}

const NavbarListMappingId = {
	[NavbarLinkList.HOME]: "navlink--home",
	[NavbarLinkList.END_OF_LIFE_PLANNING]: "navlink--end-of-life-planning",
	[NavbarLinkList.WHEN_DEATH_HAPPENS]: "navlink--when-death-happens",
	[NavbarLinkList.VAULT]: "navlink--vault",
	[NavbarLinkList.SEARCH]: "navlink--results",
};

const USE_DESKTOP_MAX_WIDTH = {
	en: {
		HOME: false,
		EOLP: false,
		WDH: false,
		VAULT: false,
	},
	ml: {
		HOME: false,
		EOLP: true,
		WDH: true,
		VAULT: true,
	},
	zh: {
		HOME: false,
		EOLP: false,
		WDH: false,
		VAULT: false,
	},
	ta: {
		HOME: false,
		EOLP: true,
		WDH: false,
		VAULT: false,
	},
};

const MAX_NAVINK_WIDTH = {
	en: {
		HOME: 156,
		EOLP: 110,
		WDH: 110,
		VAULT: 156,
	},
	ml: {
		HOME: 60,
		EOLP: 120,
		WDH: 160,
		VAULT: 150,
	},
	zh: {
		HOME: 156,
		EOLP: 156,
		WDH: 156,
		VAULT: 156,
	},
	ta: {
		HOME: 110,
		EOLP: 254,
		WDH: 140,
		VAULT: 145,
	},
};

const NavbarPublic = (props: IProps): JSX.Element => {
	const router = useRouter();
	const [, selectedLanguage] = useGlobalLanguageState();
	const [navText, setNavText] = useState<ITranslatedObject[]>(translations["en"]);
	const [showCompressedNav, setShowCompressedNav] = useState<boolean>(false);
	const [isDesktop, setIsDesktop] = useState<boolean>();
	const [selected, setSelected] = useState<string>(NavbarListMappingId[window.location.pathname]);
	const { isSingpassFlow } = useEwillsForm();
	const { sid } = useHdbParkingForm();
	const { pathname } = window.location;

	// Check for ACP 3.0
	const isSimpleHeader = showSimplifiedHeaderAndFooter(pathname);

	const handleScroll = useCallback(() => {
		// Compress and expand
		if (typeof window === "undefined") {
			return;
		}
		if (window.scrollY > 50) {
			setShowCompressedNav(true);
		} else if (window.scrollY < 20) {
			setShowCompressedNav(false);
		}
	}, []);

	useEffect(() => {
		window.addEventListener("resize", onWindowResize);
		return (): void => {
			window.removeEventListener("resize", onWindowResize);
		};
	});

	useEffect(() => {
		// set isDesktop on load
		onWindowResize();
	}, []);

	useEffect(() => {
		const newText = translations[selectedLanguage];
		setNavText(newText);
		setSelected(NavbarListMappingId[window.location.pathname]);
	}, [selectedLanguage, window.location.pathname]);

	useEffect(() => {
		document.addEventListener("scroll", handleScroll);
		return () => {
			document.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll]);

	const onWindowResize = () => {
		if (document.body.clientWidth >= 1200) {
			// MEDIA QUERIES $medium-width: 1200px;
			setIsDesktop(true);
			return;
		}
		setIsDesktop(false);
	};

	const navigateToHome = () => {
		if (router.pathname !== NavbarLinkList.HOME) {
			router.push(NavbarLinkList.HOME);
		}
	};

	const isActive = (path: string): boolean => {
		return router.pathname === path;
	};

	const getNameFromCookie = (): string => {
		const name = getCookie("name");
		if (name) {
			return decodeURIComponent(name);
		}
		return "UNKNOWN_NAME";
	};

	const logoutHandler = async () => {
		await deleteSid(sid);
		setLogout();
	};

	const handleOnNavItemClick = (item: NavItemProps<unknown>) => {
		if (item.itemType === "component") return;

		if (item.href) {
			router.push(item.href);
		}
	};

	const getProfileLinks = (notificationCountLabel?: string): JSX.Element => {
		return (
			<>
				<UserName />
				<NavLink
					type="avatar"
					isMobile={true}
					title={navText[8].translation}
					href="/vault/profile"
					active={isActive("/vault/profile/")}
				/>
				<UserAllocatedDistribution id="navlink--notifications">
					<NavLink
						type="avatar"
						isMobile={true}
						title={navText[9].translation}
						href={"/vault/notifications"}
						active={isActive("/vault/notifications/")}
					/>
					{notificationCountLabel && (
						<NotificationCountLabel className="description">
							{notificationCountLabel}
						</NotificationCountLabel>
					)}
				</UserAllocatedDistribution>
				<NavLink
					type="avatar"
					isMobile={true}
					title={navText[10].translation}
					href="/vault/settings"
					active={isActive("/vault/settings/")}
				/>
				<NavbarDrawerButtonWrapper>
					<NavbarDrawerButton id="navlink--log-out" styleType="secondary" onClick={() => logoutHandler()}>
						{navText[11].translation}
					</NavbarDrawerButton>
				</NavbarDrawerButtonWrapper>
			</>
		);
	};

	const renderNavbarAvatar = (notifications?: number): JSX.Element => {
		const notificationCountLabel = notifications ? (notifications > 9 ? "9+" : String(notifications)) : "";
		return (
			<div className="avatar">
				{isDesktop ? (
					<Menu>
						<MenuButton aria-label="User menu">
							<NavbarAvatarButton id="navbar__avatar__toggle" className="navbar__avatar__button">
								{getNameFromCookie().charAt(0)}
							</NavbarAvatarButton>
						</MenuButton>

						<MenuList portal={false}>
							<div className="navbar__avatar-profile-name" aria-hidden={true}>
								<span>{getNameFromCookie()}</span>
							</div>
							<ProfileDropdownWrapper>
								<MenuItem onSelect={() => void Router.push("/vault/profile")}>
									<div id="navlink--profile" className="navbar__avatar-text">
										<span className="text">{navText[8].translation}</span>
									</div>
								</MenuItem>
								<MenuItem onSelect={() => void Router.push("/vault/notifications")}>
									<div id="navlink--notifications" className="navbar__avatar-text with-multi-element">
										<span className="text">{navText[9].translation}</span>
										{notificationCountLabel && (
											<span className="description">{notificationCountLabel}</span>
										)}
									</div>
								</MenuItem>
								<MenuItem onSelect={() => void Router.push("/vault/settings")}>
									<div id="navlink--settings" className="navbar__avatar-text">
										<span className="text">{navText[10].translation}</span>
									</div>
								</MenuItem>
								<MenuItem onSelect={logoutHandler}>
									<div id="navlink--log-out" className="navbar__avatar-text">
										<span className="text">{navText[11].translation}</span>
									</div>
								</MenuItem>
							</ProfileDropdownWrapper>
						</MenuList>
					</Menu>
				) : (
					<NavbarDrawer
						userName={getNameFromCookie()}
						navigationLinks={getProfileLinks(notificationCountLabel)}
						closeTerm={navText[7].translation}
						isProfile={true}
					/>
				)}

				{notifications !== undefined && notifications > 0 && (
					<NotificationAlert
						data-testid="navbar--avatar-notification-count"
						className="navbar__avatar__notification-count-label"
					>
						{isDesktop && notificationCountLabel}
					</NotificationAlert>
				)}
			</div>
		);
	};

	const loginButton = (): JSX.Element => {
		const expiry = getCookie("expiry");

		const sessionStatus = determineSessionStatus(expiry);
		if (sessionStatus === SessionStatusType.active) {
			return <div className="navbar__avatar">{renderNavbarAvatar(props.notifications)}</div>;
		}

		if (isDesktop) {
			return (
				<>
					<BasicLink
						id="navbar__button__login"
						onClick={() => {
							if (isSingpassFlow !== undefined) {
								sessionStorage.clear();
							}
							window.location.href = withAuthRedirect("/vault");
						}}
						className="login-button"
						name={navText[6].translation}
						type="secondary-link-responsive"
						disablePrefetch={true}
						isAPIRoute={true}
					/>
				</>
			);
		}
		return <></>;
	};

	const UserName = () => {
		return (
			<>
				<ProfileNameWrapper id="navbar--avatar-profile-name--mobile">
					{getNameFromCookie().toUpperCase()}
				</ProfileNameWrapper>
			</>
		);
	};

	const mobileWithSimpleHeader = (): JSX.Element => {
		return (
			<MyLegacyNavbar
				className="mylegacy-navbar"
				id="navbar__content_simplified"
				items={{
					desktop: [],
					mobile: [
						{
							itemType: "component",
							children: <UserName />,
						},
						{
							href: "/vault/profile",
							id: "navlink--profile",
							children: navText[8].translation,
						},
						{
							id: "navlink--notifications",
							children: navText[9].translation,
							href: "/vault/notifications",
						},
						{
							id: "navlink--settings",
							children: navText[10].translation,
							href: "/vault/settings",
						},
						{
							itemType: "component",
							children: (
								<NavbarDrawerButtonWrapper>
									<NavbarDrawerButton
										id="navlink--log-out"
										styleType="secondary"
										onClick={() => logoutHandler()}
									>
										{navText[11].translation}
									</NavbarDrawerButton>
								</NavbarDrawerButtonWrapper>
							),
						},
					],
				}}
				resources={{
					primary: {
						brandName: "myLegacy",
						logoSrc: "/images/simplified-logo.svg",
					},
				}}
				onBrandClick={() => navigateToHome()}
				masthead={false}
			/>
		);
	};

	const mylegacyHeader = (): JSX.Element => {
		const expiry = getCookie("expiry");
		const sessionStatus = determineSessionStatus(expiry);

		return (
			<MyLegacyNavbar
				className="mylegacy-navbar"
				id="navbar__content_simplified"
				items={{
					desktop: [
						{
							href: NavbarLinkList.HOME,
							id: NavbarListMappingId[NavbarLinkList.HOME],
							children: isSimpleHeader ? (
								<></>
							) : (
								<NavbarText
									$useDesktopMaxWidth={USE_DESKTOP_MAX_WIDTH[selectedLanguage].HOME}
									$breakpointMaxWidth={MAX_NAVINK_WIDTH[selectedLanguage].HOME}
									$isSelected={selected === NavbarListMappingId[NavbarLinkList.HOME]}
								>
									{navText[0].translation}
								</NavbarText>
							),
						},
						{
							href: NavbarLinkList.END_OF_LIFE_PLANNING,
							id: NavbarListMappingId[NavbarLinkList.END_OF_LIFE_PLANNING],
							children: isSimpleHeader ? (
								<></>
							) : (
								<NavbarText
									$useDesktopMaxWidth={USE_DESKTOP_MAX_WIDTH[selectedLanguage].EOLP}
									$breakpointMaxWidth={MAX_NAVINK_WIDTH[selectedLanguage].EOLP}
									$isSelected={selected === NavbarListMappingId[NavbarLinkList.END_OF_LIFE_PLANNING]}
								>
									{navText[1].translation}
								</NavbarText>
							),
						},
						{
							id: NavbarListMappingId[NavbarLinkList.WHEN_DEATH_HAPPENS],
							children: isSimpleHeader ? (
								<></>
							) : (
								<NavbarText
									$useDesktopMaxWidth={USE_DESKTOP_MAX_WIDTH[selectedLanguage].WDH}
									$breakpointMaxWidth={MAX_NAVINK_WIDTH[selectedLanguage].WDH}
									$isSelected={selected === NavbarListMappingId[NavbarLinkList.WHEN_DEATH_HAPPENS]}
								>
									{navText[2].translation}
								</NavbarText>
							),
							href: NavbarLinkList.WHEN_DEATH_HAPPENS,
						},
						{
							id: NavbarListMappingId[NavbarLinkList.VAULT],
							children: isSimpleHeader ? (
								<></>
							) : (
								<NavbarText
									$useDesktopMaxWidth={USE_DESKTOP_MAX_WIDTH[selectedLanguage].VAULT}
									$breakpointMaxWidth={MAX_NAVINK_WIDTH[selectedLanguage].VAULT}
									$isSelected={selected === NavbarListMappingId[NavbarLinkList.VAULT]}
								>
									{navText[3].translation}
								</NavbarText>
							),
							href: NavbarLinkList.VAULT,
						},
					],
					mobile: [
						{
							href: NavbarLinkList.HOME,
							id: NavbarListMappingId[NavbarLinkList.HOME],
							children: (
								<NavbarText $isSelected={selected === NavbarListMappingId[NavbarLinkList.HOME]}>
									{navText[0].translation}
								</NavbarText>
							),
						},
						{
							href: NavbarLinkList.END_OF_LIFE_PLANNING,
							id: NavbarListMappingId[NavbarLinkList.END_OF_LIFE_PLANNING],
							children: (
								<NavbarText
									$isSelected={selected === NavbarListMappingId[NavbarLinkList.END_OF_LIFE_PLANNING]}
								>
									{navText[1].translation}
								</NavbarText>
							),
						},
						{
							id: NavbarListMappingId[NavbarLinkList.WHEN_DEATH_HAPPENS],
							children: (
								<NavbarText
									$isSelected={selected === NavbarListMappingId[NavbarLinkList.WHEN_DEATH_HAPPENS]}
								>
									{navText[2].translation}
								</NavbarText>
							),
							href: NavbarLinkList.WHEN_DEATH_HAPPENS,
						},
						{
							id: NavbarListMappingId[NavbarLinkList.VAULT],
							children: (
								<NavbarText $isSelected={selected === NavbarListMappingId[NavbarLinkList.VAULT]}>
									{navText[3].translation}
								</NavbarText>
							),
							href: NavbarLinkList.VAULT,
						},
						{
							itemType: "component",
							children:
								sessionStatus !== SessionStatusType.active ? (
									<NavbarDrawerButtonWrapper>
										<NavbarDrawerButton
											id="navbar__button__login"
											styleType="secondary"
											onClick={() => {
												if (isSingpassFlow !== undefined) {
													sessionStorage.clear();
												}
												window.location.href = withAuthRedirect("/vault");
											}}
										>
											{navText[6].translation}
										</NavbarDrawerButton>
									</NavbarDrawerButtonWrapper>
								) : (
									<></>
								),
						},
					],
				}}
				resources={{
					primary: {
						brandName: "myLegacy",
						logoSrc: "/images/simplified-logo.svg",
					},
				}}
				actionButtons={{
					desktop: [
						{
							type: "component",
							args: {
								render: isSimpleHeader ? (
									<></>
								) : (
									<div className="navbar__public">
										<div className="navbar__content__right">
											<NavLink
												title={
													<SearchbarLabel
														isSelected={
															selected === NavbarListMappingId[NavbarLinkList.SEARCH]
														}
														isDesktop={isDesktop}
													/>
												}
												href={NavbarLinkList.SEARCH}
											/>
										</div>
									</div>
								),
							},
							uncollapsible: true,
						},
						{
							type: "component",
							args: {
								render: loginButton(),
							},
							uncollapsible: true,
						},
					],
				}}
				onItemClick={handleOnNavItemClick}
				onBrandClick={() => navigateToHome()}
				masthead={false}
				selectedId={selected}
				layout={"stretch"}
				compress={showCompressedNav}
			/>
		);
	};

	return (
		<div lang={selectedLanguage}>{!isDesktop && isSimpleHeader ? mobileWithSimpleHeader() : mylegacyHeader()}</div>
	);
};

export default NavbarPublic;
