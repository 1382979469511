import { AuthAPI } from "app/api/auth";
import UserAPI from "app/api/user";
import { MyLegacyModal } from "app/components/common/MyLegacyModal";
import { getTimeInMinutesAndSeconds } from "app/hooks/timer/timer-helper";
import { useTimer } from "app/hooks/timer/use-timer";
import { useIsLifeSgApp } from "app/hooks/useIsLifeSgApp";
import { useHdbParkingForm } from "app/modules/hdb-car-parking/hooks/useHdbParkingForm";
import { getCookie } from "app/utils/cookies";
import { deleteSid } from "app/utils/redis";
import { setLogout, useURL } from "app/utils/url";
import { useRouter } from "next/router";
import { StatelessComponent, useEffect, useState } from "react";
import determineSessionStatus, { SessionStatusType } from "./determineSessionStatus";
import "./sessionModal.css";

const SESSION_HEARTBEAT_INTERVAL = 5000;
const SESSION_MODAL_DISPLAY_TIME = 120000;
const SESSION_COOKIE_DURATION_MINUTES = process.env.SESSION_COOKIE_DURATION_MINUTES || "10";

const SessionModal: StatelessComponent<{}> = (): JSX.Element => {
	let heartbeat = false;
	const { sid } = useHdbParkingForm();
	const [showInactiveModal, setShowInactiveModal] = useState(false);
	const [showPostLogoutModal, setShowPostLogoutModal] = useState(false);
	const router = useRouter();
	const { isLifeSgApp } = useIsLifeSgApp();
	// divinding SESSION_MODAL_DISPLAY_TIME with 1000 as we are changing ms to secs to cater for useTimer
	const TIMER_DURATION_IN_SECOND = SESSION_MODAL_DISPLAY_TIME / 1000;
	const { remainingTimeInSeconds } = useTimer(showInactiveModal ? TIMER_DURATION_IN_SECOND : 0);
	const [inactiveModalDescription, setinactiveModalDescription] = useState("");

	let interval;
	useEffect(() => {
		const { timeout } = useURL().query;
		if (timeout === "true") {
			setShowPostLogoutModal(true);
			const cleanUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
			void router.replace(cleanUrl);
			window.history.pushState({ path: cleanUrl }, "", cleanUrl);
		}
		window.addEventListener("click", handleActivity);
		window.addEventListener("keydown", handleActivity);
		interval = setInterval(onEachIntervalTick, SESSION_HEARTBEAT_INTERVAL, showInactiveModal);
		return (): void => {
			window.removeEventListener("click", handleActivity);
			window.removeEventListener("keydown", handleActivity);
			clearInterval(interval);
		};
	}, [isLifeSgApp, showInactiveModal]);

	const handleActivity = (): void => {
		heartbeat = true;
	};

	const onEachIntervalTick = async (disableRefresh: boolean): Promise<void> => {
		const expiry = getCookie("expiry");

		const action = determineSessionStatus(expiry, heartbeat, SESSION_MODAL_DISPLAY_TIME);
		switch (action) {
			case SessionStatusType.refresh:
				heartbeat = false;
				if (!disableRefresh) {
					await refresh();
				}
				break;
			case SessionStatusType.warning:
				setShowInactiveModal(true);
				break;
			case SessionStatusType.timeout:
				void logoutHandler();
				break;
		}
	};

	const refresh = async (): Promise<void> => {
		try {
			//TODO: check if we still need to refresh Legacy Token
			await UserAPI.refreshSession();
			await AuthAPI.refreshMOLToken();
		} catch (err) {}
	};
	const onStay = async (): Promise<void> => {
		await refresh();
		setShowInactiveModal(false);
		setShowPostLogoutModal(false);
	};

	useEffect(() => {
		if (showInactiveModal) {
			const { minutes, seconds } = getTimeInMinutesAndSeconds(remainingTimeInSeconds);
			const minOrMins = `${minutes > 1 ? "minutes" : "minute"}`;
			const noOfMins = `${minutes > 0 ? `${minutes} ${minOrMins}` : ""}`;
			setinactiveModalDescription(`You’ve been inactive for a while. To protect your privacy, you will be logged out in ${noOfMins} ${" "} ${seconds} ${
				seconds > 1 ? "seconds." : "second."
			}

		You’ll lose all information entered so far.

		Continue to keep working or end the session now.`);
		}
	}, [showInactiveModal, remainingTimeInSeconds]);

	const logoutHandler = async () => {
		await deleteSid(sid);
		setLogout();
	};

	return (
		<>
			{showInactiveModal && (
				<MyLegacyModal
					type="inactivity"
					zIndex={100005}
					title="Continue?"
					description={inactiveModalDescription}
					id="modal-session-pre-timeout"
					show={showInactiveModal}
					onModalClose={onStay}
					buttons={[
						{
							id: "modal-continue-button",
							name: "Continue",
							type: "primary",
							onClick: onStay,
						},
						{
							id: "modal-end-button",
							name: "End",
							type: "secondary",
							onClick: logoutHandler,
						},
					]}
				/>
			)}
			{showPostLogoutModal && (
				<MyLegacyModal
					zIndex={100005}
					type="logout"
					title="You’ve been logged out"
					description={`There was no activity for ${SESSION_COOKIE_DURATION_MINUTES} minutes, so you were logged out to protect your privacy. Start over again.`}
					id="modal-session-post-timeout"
					show={showPostLogoutModal}
					onModalClose={logoutHandler}
					buttons={[
						{
							id: "modal-logout-button",
							name: "OK",
							type: "primary",
							onClick: logoutHandler,
						},
					]}
				/>
			)}
		</>
	);
};

export default SessionModal;
