import { ErrorDisplay, ErrorDisplayType } from "@lifesg/react-design-system/error-display";
import { Modal, ModalProps } from "@lifesg/react-design-system/modal";
import { ResourceScheme } from "@lifesg/react-design-system/theme";
import { IButtonProps } from "app/components/basic/Button";
import { useIsLifeSgApp } from "app/hooks/useIsLifeSgApp";
import { useEffect } from "react";
import { Content, CTAWrapper, Description, Header, ModalBox, ModalButton, SrollableDiv } from "./styles";

interface IMyLegacyModal extends Omit<ModalProps, "children" | "title"> {
	title: JSX.Element | string;
	description: JSX.Element | string;
	buttons: IButtonProps[];
	show: boolean;
	onModalClose?: () => void;
	id?: string;
	width?: string;
	maxWidth?: string;
	illustrationScheme?: ResourceScheme | undefined;
	type?: ErrorDisplayType;
}

export const MyLegacyModal = ({
	id,
	title,
	description,
	show = false,
	buttons,
	onModalClose,
	width,
	maxWidth,
	illustrationScheme = "mylegacy",
	type = "confirmation",
	...props
}: IMyLegacyModal): JSX.Element => {
	const { mastheadHeight } = useIsLifeSgApp();
	// this handles the closing of modal if user press forward/back navigation on browser/mobile
	useEffect(() => {
		const closeModal = () => {
			if (onModalClose) {
				onModalClose();
			}
		};
		window.addEventListener("popstate", closeModal);

		return () => {
			window.removeEventListener("popstate", closeModal);
		};
	}, []);

	return (
		<Modal show={show} {...props} style={{ top: `${mastheadHeight}px`, height: `calc(100% - ${mastheadHeight}px` }}>
			<SrollableDiv>
				<ModalBox onClose={onModalClose} style={{ maxWidth: maxWidth, width: width }}>
					<Content id={id}>
						<ErrorDisplay
							illustrationScheme={illustrationScheme}
							type={type}
							title={typeof title === "string" ? <Header>{title}</Header> : title}
							description={
								typeof description === "string" ? <Description>{description}</Description> : description
							}
						/>

						<CTAWrapper>
							{buttons.map(({ ...props }, index) => {
								return <ModalButton key={index} {...props} />;
							})}
						</CTAWrapper>
					</Content>
				</ModalBox>
			</SrollableDiv>
		</Modal>
	);
};
